import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, NakedTextFieldRedux, SelectRedux, DateTimeRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import InputAdornment from '@material-ui/core/InputAdornment';
import {enumToJa, numberWithDelimiter, dateTimeFormat, yearMonthFormat} from '../../../utils/format';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ReplayIcon from '@material-ui/icons/Replay';
import PriceChangeDialog from '../../../components/Dialog/PriceChangeDialog';
import IconButton from '@material-ui/core/IconButton';
import * as Validator from '../../../utils/validate';


function Form(props) {

  const [sex, setSex] = useState("");
  const [status, setStatus] = useState("");

  const [authStatus, setAuthStatus] = useState(
    [
      { name: "auth", label: "与信中" },
      { name: "paid", label: "決済済み" },
      { name: "auth_cancel", label: "キャンセル" }
    ]
  )

  const [paidStatus, setPaidStatus] = useState(
    [
      { name: "paid", label: "決済済み" },
      { name: "pay_cancel", label: "返金" }
    ]
  )

  const [normalStatus, setNormalStatus] = useState(
    [
      { name: "not_pay", label: "未決済" },
      { name: "auth", label: "与信中" },
      { name: "paid", label: "決済済み" },
      { name: "pay_cancel", label: "返金" }
    ]
  )

  const [cvsStatus, setCvsStatus] = useState(
    [
      { name: "temporary", label: "仮登録" },
      { name: "cvs_cancel", label: "キャンセル" }
    ]
  )

  const {
    classes,
    handleSubmit,
    paymentHistoryData,
    submitForm
  } = props;

  const [dialogData, setDialogData] = useState(
    { 
      open: false
    }
  );

  return (
    <div>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={10} md={6}>
          <PapperBlock title="決済フォーム情報" whiteBg icon="ios-browsers-outline" desc="">
            <List>
              <Fragment key="決済フォーム名">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="決済フォーム名" />
                  <Typography variant="body2" style={{paddingLeft: '200px'}}>{paymentHistoryData.payment_form_name}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="氏名">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="氏名" />
                  <Typography variant="body2">{props.internalValues.browsingRight == 1 ? paymentHistoryData.user_name : "-----"}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="メールアドレス">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="メールアドレス" />
                  <Typography variant="body2">{props.internalValues.browsingRight == 1 && paymentHistoryData.user ? paymentHistoryData.user.email : "-----"}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="性別">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="性別" />
                  <Typography variant="body2">{props.internalValues.browsingRight == 1 && paymentHistoryData.user ? enumToJa(paymentHistoryData.user.sex) : "-----"}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="年齢">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="年齢" />
                  <Typography variant="body2">{props.internalValues.browsingRight == 1 ? paymentHistoryData.user_age : "--"}{paymentHistoryData.user_age ? "歳" : ""}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="電話番号">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="電話番号" />
                  <Typography variant="body2">{props.internalValues.browsingRight == 1 && paymentHistoryData.user ? paymentHistoryData.user.tel : "-----"}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              {
                paymentHistoryData.form_item_answers ? paymentHistoryData.form_item_answers.map( (item, index) => {
                  switch (item.form_type){
                    case "select":
                    case "nest_select":
                      return(
                        <Fragment key={item.name}>
                          <ListItem className={classes.listItem}>
                            <ListItemText primary={item.name} />
                            { item.form_item_answer_selects.map( (select, index) => {
                              return(
                                <Typography variant="body2" key={index}>{enumToJa(select.value)}</Typography>
                              )
                              })
                            }
                          </ListItem>
                          <li>
                            <Divider />
                          </li>
                        </Fragment>
                        );
                    case "text_area":
                      return(
                        <Fragment key={item.name}>
                          <ListItem className={classes.listItem}>
                            <ListItemText primary={item.name} />
                            <Typography variant="body2"　style={{paddingLeft: '200px'}}>{item.answer.split("\n").map((line, key) => <span key={key}>{line}<br /></span>)}</Typography>
                          </ListItem>
                          <li>
                            <Divider />
                          </li>
                        </Fragment>
                      );
                    default:
                      return(
                        <Fragment key={item.name}>
                          {props.internalValues.browsingRight != 1 && (item.name == "名前(セイ)" || item.name == "名前(メイ)" || item.name == "携帯電話番号" || item.name == "郵便番号" || item.name == "都道府県" || item.name == "市区町村" || item.name == "番地") ? 
                            <ListItem className={classes.listItem}>
                              <ListItemText primary={item.name} />
                              <Typography variant="body2" style={{paddingLeft: '200px'}}>-----</Typography>
                            </ListItem>
                            :
                            <ListItem className={classes.listItem}>
                              <ListItemText primary={item.name} />
                              <Typography variant="body2" style={{paddingLeft: '200px'}}>{item.answer}</Typography>
                            </ListItem>
                          }
                          <li>
                            <Divider />
                          </li>
                        </Fragment>
                      );
                  }
                }) : ""
              }
            </List>
          </PapperBlock>
          </Grid>
          <Grid item xs={10} md={6}>
          <PapperBlock title="決済情報" whiteBg icon="ios-card" desc="">
          <form onSubmit={handleSubmit}>
          <List>
              <Fragment key="決済番号">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="決済番号" />
                  <Typography variant="body2">{paymentHistoryData.order_number}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="加盟店">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="加盟店" />
                  <Typography variant="body2">{paymentHistoryData.company_name}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="決済手段">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="決済手段" />
                  <Typography variant="body2">{enumToJa(paymentHistoryData.payment_history_type)}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="決済日時">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="決済日時" />
                  <Typography variant="body2" >{paymentHistoryData.order_date ? dateTimeFormat(paymentHistoryData.order_date) : ""}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="決済金額">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="決済金額" />
                  <Typography variant="body2">{numberWithDelimiter(paymentHistoryData.price)}円</Typography>
                  { ((paymentHistoryData.payment_type == 'credit_card' && paymentHistoryData.status == 'paid') || (paymentHistoryData.payment_type == 'cvs' && paymentHistoryData.status == 'temporary')) &&
                    <IconButton
                      className={classes.menuButton}
                      aria-label="Menu"
                      onClick={() => {setDialogData({open: true})}}
                    >
                      <ReplayIcon />
                    </IconButton>
                  }
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="SPSV自由領域2">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="SPSV自由領域2" />
                  <Typography variant="body2">{paymentHistoryData.spsv_free_2}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="SPSV自由領域3">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="SPSV自由領域3" />
                  <Typography variant="body2">{paymentHistoryData.spsv_free_3}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="何月利用分">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="何月利用分" />
                  <Typography variant="body2">{yearMonthFormat(paymentHistoryData.use_month)}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="SPSVレスポンスコード">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="SPSVレスポンスコード" />
                  <Typography variant="body2">{paymentHistoryData.response_cd}</Typography>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
              <Fragment key="ステータス">
                <ListItem className={classes.listItem}>
                  <ListItemText primary="ステータス" />
                  <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
                    <Grid item xs={8} sm={6} style={{paddingTop: '20px'}}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="status"
                          fullWidth
                          component={SelectRedux}
                          className={classes.field}
                          onChange={evt => setStatus({...status, name:evt.target.value})}
                          disabled={props.paymentHistoryData.editable ? false : true}
                        >
                          {props.paymentHistoryData.status == 'pay_cancel' && 
                          <MenuItem value="pay_cancel">返金</MenuItem>}
                          {props.paymentHistoryData.status == 'auth_cancel' && 
                          <MenuItem value="auth_cancel">キャンセル</MenuItem>}
                          {props.paymentHistoryData.status == 'cvs_cancel' && 
                          <MenuItem value="cvs_cancel">キャンセル</MenuItem>}
                          {props.paymentHistoryData.status == 'paid' && (props.paymentHistoryData.payment_type == "cvs" ? <MenuItem value="paid">決済済み</MenuItem> :
                            paidStatus.map((item, index) => {
                              return(
                                <MenuItem value={item.name} key={index} id={index}>{item.label}</MenuItem>
                              )}
                            )
                          )}
                          {props.paymentHistoryData.status == 'auth' && 
                            authStatus.map((item, index) => {
                              return(
                                <MenuItem value={item.name} key={index} id={index}>{item.label}</MenuItem>
                              )}
                            )}
                          {props.paymentHistoryData.status == 'not_pay' && 
                            normalStatus.map((item, index) => {
                              return(
                                <MenuItem value={item.name} key={index} id={index}>{item.label}</MenuItem>
                              )}
                            )}
                          {props.paymentHistoryData.status == 'temporary' && 
                            cvsStatus.map((item, index) => {
                              return(
                                <MenuItem value={item.name} key={index} id={index}>{item.label}</MenuItem>
                              )}
                            )}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </ListItem>
                <li>
                  <Divider />
                </li>
              </Fragment>
            </List>
                <div className={classes.btnArea}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid container item xs={12} sm={6} alignItems="center" justifyContent="center">
                      <FormControl className={classes.formControl}>
                        <Field
                          name="payment_password"
                          component={TextFieldRedux}
                          type="password"
                          label="決済パスワード"
                          required
                          validate={[Validator.required]}
                          className={classes.field}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                    </Grid>
                    <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/payment_history/index">
                      戻る
                    </Button>
                    <Button variant="contained" color="primary" size="large" type="submit" disabled={props.paymentHistoryData.editable ? false : true}>
                      更新
                    </Button>
                  </Grid>
                </div>
              </form>
          </PapperBlock>
        </Grid>
      </Grid>
      <PriceChangeDialog dialogData={dialogData} onSubmit={(values) => submitForm(values)} />
    </div>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'paymentHistoryForm',
  enableReinitialize: true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    paymentHistoryData: state.getIn(['paymentHistoryReducers', 'paymentHistoryData']),
    internalValues: state.getIn(['internalReducers', 'internalValues'])
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
