import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from 'dan-components/Tables/tableStyle-jss';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import Pagination from '../Pagination/Pagination';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

function List(props) {
  const { classes, listItems, url, role, actions, nameJumpActions, browsingRight, login, is_show_hide } = props;
  const { data, meta } = props.searchResult;

  const [page, setPage] = useState(meta['current-page'])

  // ページネーション
  const onPageChange = (page) => {
    props.submitForm(null, page)
    setPage(page);
  }
  const onPrev = () => {
    if (meta['prev-page'] != null) {
      props.submitForm(null, meta['prev-page'])
      setPage(meta['prev-page']);
    }
  }
  const onNext = () => {    
    if (meta['next-page'] != null) {
      props.submitForm(null, meta['next-page'])
      setPage(meta['next-page']);
    }
  }
  const onGoFirst = () => {
    props.submitForm(null, 1)
    setPage(1);
  }
  const onGoLast = () => {
    props.submitForm(null, meta['total-pages'])  
    setPage(meta['total-pages']);
  }

  const format = (currentData, listItem) => {
    return (
      listItem.name === "exported"
        ? currentData.attributes[listItem.name] ? "エクスポート済" : ""
        : listItem.format && typeof listItem.format === 'function'
          ? listItem.format(currentData.attributes)
          : currentData.attributes[listItem.name]
    )
  }

  const determinePaymentComplete = (isPaid) => {
    return ( isPaid ? true : false )
  }

  return (
    <Fragment>
      <div className={classes.rootTable}>
        <Table className={classNames(classes.table, classes.stripped)}>
          <TableHead>
            <TableRow>
            {listItems.map((listItem, index) => ([
              role === 'super' ? 
                <TableCell key={index} padding="normal">{listItem.label}</TableCell> :
                listItem.super !== true && <TableCell key={index} padding="normal">{listItem.label}</TableCell>
            ]))}
            <TableCell align="center">アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(currentData => ([
              <TableRow key={currentData.id}>
                {listItems.map((listItem, index) => ([
                  browsingRight !== 1 && (listItem.name === "user-name" || listItem.name === "email") ? (
                    login === undefined ? (
                      <TableCell key={index} padding="normal">-----</TableCell>
                    ) : login !== currentData.attributes.login ? (
                      <TableCell key={index} padding="normal">-----</TableCell>
                    ) : (
                      <TableCell key={index} padding="normal">{format(currentData, listItem)}</TableCell>
                    )
                  ) : (
                    role === 'super' ? (
                      listItem.url && listItem.id ? (
                        <TableCell key={index} padding="normal">
                          <a href={`${listItem.url}/${currentData.attributes[listItem.id]}`} target="_blank" rel="noopener noreferrer">
                            {format(currentData, listItem)}
                          </a>
                        </TableCell>
                      ) : (
                        <TableCell key={index} padding="normal">{format(currentData, listItem)}</TableCell>
                      )
                    ) : (
                      listItem.super !== true && (
                        listItem.url && listItem.id ? (
                          <TableCell key={index} padding="normal">
                            <a href={`${listItem.url}/${currentData.attributes[listItem.id]}`} target="_blank" rel="noopener noreferrer">
                              {format(currentData, listItem)}
                            </a>
                          </TableCell>
                        ) : (
                          <TableCell key={index} padding="normal">{format(currentData, listItem)}</TableCell>
                        )
                      )
                    )
                  ),
                ]))}
                <TableCell align="center" padding="normal">
                  <Grid container spacing={1} justifyContent="center">
                    { !is_show_hide && url && (
                      <Grid item>
                        <Button variant="contained" color="primary" size="small" component={Link} to={url+`${currentData.id}`}>
                          詳細
                        </Button>
                      </Grid>
                    )}
                    {actions ? actions.map((action, index) => ([
                      action.label == '削除' && currentData.attributes['is-paid'] ?
                      <Grid item key={index}>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          size="small" 
                          onClick={() => action.function(currentData)} 
                          disabled={determinePaymentComplete(currentData.attributes['is-paid'])}
                        >
                          {action.label}
                        </Button> 
                      </Grid>
                    :
                      <Grid item key={index}>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          size="small" 
                          onClick={() => action.function(currentData)}
                        >
                          {action.label}
                        </Button> 
                      </Grid>
                    ])): ""}
                    {nameJumpActions ? nameJumpActions.map((action, index) => ([
                      <Grid item key={index}>
                        <Button variant="contained" color="primary" size="small" onClick={() => action.function(currentData.attributes.name)}>
                          {action.label}
                        </Button> 
                      </Grid>
                    ])): ""}
                  </Grid>
                </TableCell>
              </TableRow>
            ]))}
          </TableBody>
        </Table>
        <Box component="div" display="inline">全 { meta['total-count'] }件</Box>
        <Pagination
          curpage={page}
          totpages={meta['total-pages']}
          boundaryPagesRange={1}
          onChange={onPageChange}
          siblingPagesRange={1}
          hideEllipsis={false}
          onPrev={onPrev}
          onNext={onNext}
          onGoFirst={onGoFirst}
          onGoLast={onGoLast}
        />
      </div>
    </Fragment>
  );
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withCookies(withRouter(List)));