import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import * as Validator from '../../../utils/validate';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

function StaffForm(props) {
  const {
    classes,
    handleSubmit,
    isNew,
    isShow,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <section className={classes.formWrap}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="last_name"
                  label="担当者（姓）"
                  fullWidth
                  component={TextFieldRedux}
                  className={classes.field}
                  type={props.internalValues.browsingRight == 1 ? 'text' : 'password'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="first_name"
                  label="担当者（名）"
                  fullWidth
                  component={TextFieldRedux}
                  className={classes.field}
                  type={props.internalValues.browsingRight == 1 ? 'text' : 'password'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="department"
                  label="部署"
                  fullWidth
                  component={TextFieldRedux}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="email"
                  label="メールアドレス"
                  fullWidth
                  validate={[Validator.email]}
                  component={TextFieldRedux}
                  className={classes.field}
                  type={props.internalValues.browsingRight == 1 ? 'text' : 'password'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="tel"
                  label="電話番号"
                  fullWidth
                  component={TextFieldRedux}
                  className={classes.field}
                  type={props.internalValues.browsingRight == 1 ? 'text' : 'password'}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className={classes.btnArea}>
          <Grid container justifyContent="center">
            {isNew || isShow ? '' :
              <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/company/index">           
                戻る
              </Button>
            }
            <Button variant="contained" color="primary" size="large" type="submit" disabled={props.invalid}>
              {isNew ? '登録' : '更新'}
            </Button>
          </Grid>
        </div>
      </section>
    </form>
  );
}
StaffForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired
};
const ServiceFormReduxed = reduxForm({
  form: 'companyForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(StaffForm);
const reducer = 'ui';
const FormInit = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    internalValues: state.getIn(['internalReducers', 'internalValues'])
  }),
)(ServiceFormReduxed);
export default withStyles(styles)(FormInit);