import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom';
import NotFound from '../Pages/Standalone/NotFoundDedicated';
import Auth from './Auth';
import Application from './Application';
import LoginDedicated from '../Pages/Standalone/LoginDedicated';
import ThemeWrapper from './ThemeWrapper';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
import LoginRequiredRoute from '../../components/LoginRequiredRoute/LoginRequiredRoute' 

function App() {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src =
      '/mini-profiler-resources/includes.js?v=12b4b45a3c42e6e15503d7a03810ff33'
    script.type = 'text/javascript'
    script.id = 'mini-profiler'
    script.setAttribute(
      'data-css-url',
      '/mini-profiler-resources/includes.css?v=12b4b45a3c42e6e15503d7a03810ff33'
    )
    script.setAttribute('data-version', '12b4b45a3c42e6e15503d7a03810ff33')
    script.setAttribute('data-path', '/mini-profiler-resources/')
    script.setAttribute('data-horizontal-position', 'left')
    script.setAttribute('data-vertical-position', 'top')
    script.setAttribute('data-ids', '')
    script.setAttribute('data-trivial', 'false')
    script.setAttribute('data-children', 'false')
    script.setAttribute('data-max-traces', '20')
    script.setAttribute('data-controls', 'false')
    script.setAttribute('data-total-sql-count', 'false')
    script.setAttribute('data-authorized', 'true')
    script.setAttribute('data-toggle-shortcut', 'Alt+P')
    script.setAttribute('data-start-hidden', 'false')
    script.setAttribute('data-collapse-results', 'true')
    script.setAttribute('data-hidden-custom-fields', '')
    script.setAttribute('data-html-container', 'body')
    document.head.appendChild(script)
  })

  return (
    <ThemeWrapper>
      <Switch>
        <Route path="/admin" exact component={LoginDedicated} />
        <Route path="/admin/top" component={Application} />
        <Route component={Auth} />
        <Route component={NotFound} />
      </Switch>
    </ThemeWrapper>
  );
}

export default App;
