import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../../../components/Panel/panel-jss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ShortTextIcon from '@material-ui/icons/ShortText';
import RemoveIcon from '@material-ui/icons/Remove';
import MaximizeIcon from '@material-ui/icons/Maximize';
import SortIcon from '@material-ui/icons/Sort';
import LooksOneOutlinedIcon from '@material-ui/icons/LooksOneOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import GavelIcon from '@material-ui/icons/Gavel';
import LabelIcon from '@material-ui/icons/Label';
import ImageIcon from '@material-ui/icons/Image';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";


function FloatingPanel(props) {
  const [expanded, setExpanded] = useState(false);
  const {
    classes,
    openForm,
    extraSize,
    setOpenForm,
    addItem,
    isDisplay
  } = props;
  const [addressGroupName, setAddressGroupName] = useState("");
  const [isAddressGroupDialogOpen, setIsAddressGroupDialogOpen] = useState(false);
  const [addressGroupError, setAddressGroupError] = useState(false);
  const handleClick = (name = "", form_type = "", placeholder = "", description = "", is_required = false, is_hidden = false, form_item_selects_attributes = [], name_disabled = false, form_type_disabled = false) => {
    addItem(name, form_type, placeholder, description, is_required, is_hidden, form_item_selects_attributes, name_disabled, form_type_disabled)
    setOpenForm(false)
  };
  const handleAddressGroupSubmit = () => {
    if (!addressGroupName.trim()) {
      setAddressGroupError(true);
      return;
    }
    handleClick(`郵便番号（${addressGroupName}）`, "tel", `郵便番号（${addressGroupName}）`, "", false, false, [], true, true);
    handleClick(`都道府県（${addressGroupName}）`, "text", `都道府県（${addressGroupName}）`, "", false, false, [], true, true);
    handleClick(`市区町村（${addressGroupName}）`, "text", `市区町村（${addressGroupName}）`, "", false, false, [], true, true);
    handleClick(`番地（${addressGroupName}）`, "text", `番地（${addressGroupName}）`, "", false, false, [], true, true);
    handleClick(`建物名（${addressGroupName}）`, "text", `建物名（${addressGroupName}）`, "", false, false, [], true, true);
    setIsAddressGroupDialogOpen(false);
  };

  function handleClose() {
    setIsAddressGroupDialogOpen(false);
    setAddressGroupError(false);
  }

  return (
    <div>
      <div className={
        classNames(
          classes.formOverlay,
          openForm ? classes.showForm : classes.hideForm
        )}
      />
      <section className={
        classNames(
          !openForm ? classes.hideForm : classes.showForm,
          classes.floatingForm,
          classes.formTheme,
          extraSize && classes.large
        )}
      >
        <header>
          よくある項目
          <div className={classes.btnOpt}>
            <IconButton className={classes.closeButton} onClick={() => setOpenForm(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </div>
        </header>
        <div>
          <List component="nav">
            <ListItem button onClick={() => handleClick("名前(姓)", "text", "名前(姓)")}>
            <ListItemIcon>
              <AccountCircleIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="名前(姓)" />
            </ListItem>
            <ListItem button onClick={() => handleClick("名前(名)", "text", "名前(名)")}>
            <ListItemIcon>
              <AccountCircleIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="名前(名)" />
            </ListItem>
            <ListItem button onClick={() => handleClick("名前(セイ)", "katakana", "名前(セイ)")}>
            <ListItemIcon>
              <AccountCircleIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="名前(セイ)" />
            </ListItem>
            <ListItem button onClick={() => handleClick("名前(メイ)", "katakana", "名前(メイ)")}>
            <ListItemIcon>
              <AccountCircleIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="名前(メイ)" />
            </ListItem>
            <ListItem button onClick={() => handleClick("固定電話番号", "tel", "固定電話番号")}>
            <ListItemIcon>
              <PhoneIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="固定電話番号" />
            </ListItem>
            <ListItem button onClick={() => handleClick("携帯電話番号", "tel", "携帯電話番号")}>
            <ListItemIcon>
              <PhoneIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="携帯電話番号" />
            </ListItem>
            <ListItem button onClick={() => handleClick("性別", "select", "性別", "", false, false, [{value: "男性"}, {value: "女性"}, {value: "その他"}])}>
            <ListItemIcon>
              <PeopleIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="性別" />
            </ListItem>
            <ListItem button onClick={() => handleClick("生年月日", "date", "生年月日")} >
            <ListItemIcon>
              <DateRangeIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="生年月日" />
            </ListItem>
            <ListItem button onClick={() => {
              handleClick("郵便番号", "tel", "郵便番号", "", false, false, [], true, true);
              handleClick("都道府県", "text", "都道府県", "", false, false, [], true, true);
              handleClick("市区町村", "text", "市区町村", "", false, false, [], true, true);
              handleClick("番地", "text", "番地", "", false, false, [], true, true);
              handleClick("建物名", "text", "建物名", "", false, false, [], true, true);
            }}>
              <ListItemIcon>
              <HomeIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="住所" />
            </ListItem>
          </List>
        </div>
        <header>
          カスタム項目
        </header>
        <div>
          <List component="nav">
            <ListItem button onClick={() => handleClick("", "text", "")}>
            <ListItemIcon>
              <RemoveIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="単行" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "text_area", "")}>
            <ListItemIcon>
              <SortIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="複数行" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "tel", "")}>
            <ListItemIcon>
              <LooksOneOutlinedIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="数字" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "katakana", "")}>
            <ListItemIcon>
              <RemoveIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="カタカナ" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick("", "radio", "")}>
            <ListItemIcon>
              <RadioButtonCheckedOutlinedIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="ラジオボタン" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick("", "checkbox", "")}>
            <ListItemIcon>
              <CheckBoxOutlinedIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="チェックボックス" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick("", "select", "")}>
            <ListItemIcon>
              <ArrowDropDownCircleOutlinedIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="プルダウン" />
            </ListItem>
            {isDisplay &&
              <ListItem button onClick={() => handleClick("", "nest_select", "")}>
              <ListItemIcon>
                <ArrowDropDownCircleIcon className={classes.iconBlue} />
              </ListItemIcon>
              <ListItemText primary="条件分岐プルダウン" />
              </ListItem>
            }
            <ListItem button onClick={() => handleClick("", "date", "")}>
            <ListItemIcon>
              <DateRangeIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="日付" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "terms_of_service", "")}>
              <ListItemIcon>
                <GavelIcon className={classes.iconPink} />
              </ListItemIcon>
              <ListItemText primary="規約同意" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "label", "")}>
              <ListItemIcon>
                <LabelIcon className={classes.iconPink} />
              </ListItemIcon>
              <ListItemText primary="ラベル" />
            </ListItem>
            <ListItem button onClick={() => {
              setIsAddressGroupDialogOpen(true);
            }}>
              <ListItemIcon>
                <HomeIcon className={classes.iconBlue}/>
              </ListItemIcon>
              <ListItemText primary="住所"/>
            </ListItem>
            <ListItem button onClick={() => handleClick("", "email", "")}>
            <ListItemIcon>
              <MailOutlineIcon className={classes.iconPink} />
            </ListItemIcon>
            <ListItemText primary="メールアドレス" />
            </ListItem>
            <ListItem button onClick={() => handleClick("", "image", "")}>
            <ListItemIcon>
              <ImageIcon className={classes.iconBlue} />
            </ListItemIcon>
            <ListItemText primary="画像ファイル" />
            </ListItem>
          </List>
        </div>
      </section>
      {/* TODO: Dialogコンポーネント部分を切り出したい */}
      <Dialog
          open={isAddressGroupDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"住所のグループ名を入力してください"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                グループ名
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                error={addressGroupError}
                helperText={addressGroupError ? "グループ名を入力してください" : ""}
                defaultValue={addressGroupName}
                onChange={(e) => setAddressGroupName(e.target.value)}
                fullWidth
                style={{marginBottom: 20}}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
          <Button onClick={handleAddressGroupSubmit} color="primary">
            決定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

FloatingPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired
};

const FloatingPanelResponsive = withWidth()(FloatingPanel);
export default withStyles(styles)(FloatingPanelResponsive);