import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './user-jss';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import brand from 'dan-api/dummy/brand';
import logo from 'dan-images/logo.svg';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux } from './ReduxFormMUI';
import * as Validator from '../../utils/validate';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';

const TwoFactorForm = (props) => {
  const {
    classes,
    handleSubmit,
    pristine,
    submitting,
    deco,
    resendForm
  } = props;
  
  function handleResend() {
    resendForm();
  }
  
  return (
    <>
      <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
        <div className={classes.topBar}>
          <NavLink to="/" className={classes.brand}>
            <img src={logo} alt={brand.name}/>
            {brand.name}
          </NavLink>
        </div>
        <Typography variant="h5" className={classes.title} gutterBottom>
          メールで送られた認証コードを入力してください
        </Typography>
        <section className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="auth_code"
                      component={TextFieldRedux}
                      placeholder="認証コード"
                      label="認証コード"
                      required
                      validate={[Validator.required]}
                      className={classes.field}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{display: 'flex'}}>
              <Button size="small" onClick={handleResend} className={classes.buttonLink}>
                認証コードを再送する
              </Button>
            </div>
            <div className={classes.btnArea}>
              <Grid container justifyContent="center">
                <Button variant="outlined" color="primary" component={Link} to="/admin/login">
                  戻る
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  送信
                  <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine}/>
                </Button>
              </Grid>
            </div>
          </form>
        </section>
      </Paper>
    </>
  );
};
TwoFactorForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};
const TwoFactorFormReduxed = reduxForm({
  form: 'twoFactorAuthentication',
  enableReinitialize: true,
})(TwoFactorForm);
const reducer = 'ui';
const RegisterFormMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
  }),
)(TwoFactorFormReduxed);
export default withStyles(styles)(RegisterFormMapped);