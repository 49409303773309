import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import QRCode from "qrcode.react";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CopyToClipBoard from 'react-copy-to-clipboard';
import InputAdornment  from '@material-ui/core/InputAdornment';
import AssignmentIcon  from '@material-ui/icons/Assignment';

function UrlScriptDialog(props) {
  const {
    urlScriptData
  } = props;

  useEffect(() => {
    setOpen(urlScriptData.open)
  }, [urlScriptData]);

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  const downloadQRCode = () => {
    var canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${urlScriptData.url.slice(7)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const [input, setInput] = useState('');
  const [openTip, setOpenTip] = useState(false);
  const [openTip2, setOpenTip2] = useState(false);

  const handleChangeText = (e) => {
    setInput(e.target.value);
  };

  const handleCloseTip = (i) => {
    if (i === 1) {
      setOpenTip(false);
    } else {
      setOpenTip2(false);
    }
  };

  const handleClickButton = (i) => {
    if (i === 1) {
      setOpenTip(true);
    } else {
      setOpenTip2(true);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'埋め込みコード'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                スクリプト 
                <Tooltip title="Webページのhtmlへコピー・ペーストで埋め込むことで、決済フォームが設置されます。" placement="right">
                  <IconButton >
                    <i className="ion-ios-help-outline" />
                  </IconButton>
                </Tooltip>
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                defaultValue={`<iframe src="${urlScriptData.url.substring(0, urlScriptData.url.lastIndexOf('/')) + "?id=" + urlScriptData.url.substring(urlScriptData.url.lastIndexOf('/') + 1)}" frameborder="0" style="border:0;height:600px;" allowfullscreen="" scrolling="no" ></iframe>`}
                fullWidth
                disabled
                multiline={true}
                minRows={4}
                style={{marginBottom: 15}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" style={{marginTop: 5}}>
                      <Tooltip
                        arrow
                        open={openTip}
                        onClose={() => {handleCloseTip(1)}}
                        disableHoverListener
                        placement='top'
                        title='クリップボードにコピーしました'
                      >
                        <CopyToClipBoard text={`<iframe src="${urlScriptData.url.substring(0, urlScriptData.url.lastIndexOf('/')) + "?id=" + urlScriptData.url.substring(urlScriptData.url.lastIndexOf('/') + 1)}" frameborder="0" style="border:0;height:600px;" allowfullscreen="" scrolling="no" ></iframe>`}>
                          <IconButton
                            onClick={() => {handleClickButton(1)}}
                          >
                            <AssignmentIcon />
                          </IconButton>
                        </CopyToClipBoard>
                      </Tooltip>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                フォームURL 
                <Tooltip title="ボタンや、ハイパーリンクに設定することで、決済フォームが設置されます。メールなどで直接お送りすることも可能です。" placement="right">
                  <IconButton >
                    <i className="ion-ios-help-outline" />
                  </IconButton>
                </Tooltip>
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                defaultValue={urlScriptData.url}
                fullWidth
                disabled
                style={{marginBottom: 20}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" style={{marginTop: 27}}>
                      <Tooltip
                        arrow
                        open={openTip2}
                        onClose={() => {handleCloseTip(2)}}
                        disableHoverListener
                        placement='top'
                        title='クリップボードにコピーしました'
                      >
                        <CopyToClipBoard text={urlScriptData.url}>
                          <IconButton
                            onClick={() => {handleClickButton(2)}}
                          >
                            <AssignmentIcon />
                          </IconButton>
                        </CopyToClipBoard>
                      </Tooltip>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                QRコード 
                <Tooltip title="Web画面や、店頭チラシ等に設置し、QRコードを読み込むことで決済フォームに移行します。" placement="right">
                  <IconButton >
                    <i className="ion-ios-help-outline" />
                  </IconButton>
                </Tooltip>
              </DialogContentText>
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              <QRCode
                id="qr-gen"
                value={urlScriptData.url}
                size={300}
                level={"H"}
                includeMargin={true}
              />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              <Button onClick={downloadQRCode} color="secondary" variant="contained">
                ダウンロード
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UrlScriptDialog;