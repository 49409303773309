import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import * as Validator from '../../../utils/validate';
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import SelectSuggestions from "../../../components/Forms/SelectSuggestions";
import { enumToJa } from "../../../utils/format";

const Form = props => {
  const {
    classes,
    handleSubmit,
    mailPaymentRecipientData,
    deleteForm,
    indexPaymentFormData,
  } = props;
  const statuses = ['unsent', 'sent', 'send_failed'];
  const paymentStatuses = ['not_pay', 'auth', 're_auth', 'paid', 'pay_cancel', 're_auth_capture', 'temporary', 'failed', 'auth_cancel'];
  const isSend = mailPaymentRecipientData && mailPaymentRecipientData.is_send
  return (
    <>
      <form onSubmit={handleSubmit}>
        <PapperBlock title={"配信設定詳細"} whiteBg icon="ios-contact-outline" desc="">
          <section className={classes.formWrap}>
            <div>
              <Grid container spacing={3}>
                {mailPaymentRecipientData && mailPaymentRecipientData.email &&
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="email"
                        label="メールアドレス *"
                        fullWidth
                        component={TextFieldRedux}
                        validate={[Validator.required, Validator.email]}
                        className={classes.field}
                        disabled={isSend}
                        type={props.internalValues.browsingRight == 1 || 'password'}
                      />
                    </FormControl>
                  </Grid>
                }
                {mailPaymentRecipientData && mailPaymentRecipientData.tel &&
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="tel"
                        label="電話番号 *"
                        fullWidth
                        component={TextFieldRedux}
                        validate={[Validator.required, Validator.tel]}
                        className={classes.field}
                        disabled={isSend}
                        type={props.internalValues.browsingRight == 1 || 'password'}
                      />
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="no"
                      className={classes.field}
                      component={TextFieldRedux}
                      placeholder="配信決済番号"
                      label="配信決済番号 *"
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="payment_form_id"
                      label='決済フォーム *'
                      component={SelectSuggestions}
                      inputId="react-select-single"
                      TextFieldProps={{
                        label: '決済フォーム *',
                        InputLabelProps: {
                          htmlFor: 'react-select-single',
                          shrink: true,
                        },
                        disabled: isSend
                      }}
                      placeholder='決済フォームを検索し選択してください'
                      validate={[Validator.required]}
                      options={
                        indexPaymentFormData && indexPaymentFormData.length > 0 &&
                        indexPaymentFormData
                          .filter(paymentForm => paymentForm.attributes['payment-form-type'] === 'normal_payment')
                          .map((paymentForm, key) => ({
                            value: paymentForm.id,
                            label: paymentForm.attributes.name
                          }))
                      }
                      className={classes.field}
                      initialValue={mailPaymentRecipientData && mailPaymentRecipientData.payment_form_id}
                      isDisabled={isSend}
                    />
                  </FormControl>
                </Grid>
                {mailPaymentRecipientData && mailPaymentRecipientData.email &&
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="subject"
                        className={classes.field}
                        component={TextFieldRedux}
                        placeholder="件名"
                        validate={[Validator.required, Validator.subjectMaxlength]}
                        label="件名 *"
                        disabled={isSend}
                      />
                    </FormControl>
                  </Grid>
                }
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="body"
                      className={classes.field}
                      component={TextFieldRedux}
                      placeholder="メッセージ"
                      label="メッセージ"
                      validate={[Validator.messageMaxlength]}
                      multiline={true}
                      minRows={10}
                      disabled={isSend}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="status"
                      label="配信ステータス *"
                      fullWidth
                      component={SelectRedux}
                      disabled={true}
                    >
                      {statuses.map(status => (
                        <MenuItem key={status} value={status}>{enumToJa(status)}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="payment_status"
                      label="決済ステータス *"
                      fullWidth
                      component={SelectRedux}
                      disabled={true}
                    >
                      {paymentStatuses.map(status => (
                        <MenuItem key={status} value={status}>{enumToJa(status)}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <div className={classes.btnArea}>
                <Grid container justifyContent="center">
                  <Button variant="outlined" color="primary" size="large" component={Link} to={`/admin/mail_payment/${mailPaymentRecipientData.mail_payment_id}`}>
                    戻る
                  </Button>
                  <Button variant="contained" color="primary" size="large" type="submit" disabled={isSend}>
                    更新
                  </Button>
                </Grid>
              </div>
              <div className={classes.btnArea}>
                <Grid container justifyContent="center">
                  <DeleteDialog color="secondary" className={classes.button} message="この配信決済を削除する" deleteForm={deleteForm} disabled={isSend}/>
                </Grid>
              </div>
            </div>
          </section>
        </PapperBlock>
      </form>
    </>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit:
  PropTypes.func.isRequired,
  pristine:
  PropTypes.bool.isRequired,
  submitting:
  PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'mailPaymentRecipientForm',
  enableReinitialize:
    true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco:
      state.getIn([reducer, 'decoration']),
    mailPaymentRecipientData:
      state.getIn(['mailPaymentRecipientReducers', 'mailPaymentRecipientData']),
    internalValues:
      state.getIn(['internalReducers', 'internalValues'])
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
