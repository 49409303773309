import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, getFormValues, getFormSyncErrors } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, DateRedux, CheckboxRedux, SwitchRedux, renderRadioGroup } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import * as Validator from '../../../utils/validate';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialDropZone from 'dan-components/Forms/MaterialDropZone';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import renderServices from './renderServices';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import renderFormItems from './renderFormItems';
import InputAdornment from '@material-ui/core/InputAdornment';
import UrlScriptDialog from '../../../components/Dialog/UrlScriptDialog';
import TextEditor from '../Companies/TextEditor';
import Radio from '@material-ui/core/Radio';
import SelectSuggestions from '../../../components/Forms/SelectSuggestions';

function Form(props) {

  const [status, setStatus] = useState("");
  const { role, companies } = props.internalValues
  const [imageFiles, setImageFiles] = useState([]);

  const {
    classes,
    handleSubmit,
    deleteForm,
    formId,
    setPaymentFormData,
    formValues,
    synchronousError,
    isNew,
    indexPaymentFormData,
    fetchIndexPaymentForm,
    fetchCompany,
    companyData
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  useEffect(() => {
    // company_idが変更された場合の処理をここに記述
    if (formValues && formValues.get("payment_form_type") === "transfer_payment" && formValues.get("company_id")) {
      const newCompanyId = formValues.get("company_id");
      fetchCompany(newCompanyId)
      if (formValues.get("payment_form_type") === "transfer_payment") {
        formValues.delete("parent_form_id");
        fetchIndexPaymentForm(newCompanyId)
      }
    }
  }, [formValues && formValues.get("company_id"), formValues && formValues.get("payment_form_type")]);

  const [urlScriptData, setUrlScriptData] = useState(
    { 
      url: "",
      open: false
    }
  );

  const phoneFormatter = (number) => {
    if (!number) return '';
    // NNN-NNN-NNNN
    const splitter = /.{1,3}/g;
    number = number.substring(0, 10);
    return number.substring(0, 7).match(splitter).join('-') + number.substring(7);
  };

  const accessToken = props.getAccessToken;

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <PapperBlock title={isNew ? "決済フォーム作成" : "決済フォーム詳細"} whiteBg icon="ios-contact-outline" desc="">
        <section className={classes.formWrap}>
          <div>
            <Grid container spacing={3}>
              {role ==='super' &&
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="company_id"
                      label="加盟店名 *"
                      fullWidth
                      component={SelectRedux}
                      validate={[Validator.required]}
                      disabled={isNew ? false : true}
                    >
                      {companies.map( (company, key) => (<MenuItem value={company.id} key={key}>{company.name}</MenuItem>) )}
                    </Field>
                  </FormControl>
                </Grid>
              }
              { !isNew &&
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="form_url"
                      label="フォームURL"
                      fullWidth
                      component={TextFieldRedux}
                      validate={[Validator.required]}
                      disabled
                      className={classes.field}
                    />
                  </FormControl>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="code"
                        label="コード *"
                        fullWidth
                        component={TextFieldRedux}
                        validate={[Validator.required, Validator.code]}
                        className={classes.field}
                        placeholder="半角英数10文字以内"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="name"
                    label="フォーム名 *"
                    fullWidth
                    component={TextFieldRedux}
                    validate={[Validator.required]}
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="description"
                    className={classes.field}
                    component={TextFieldRedux}
                    validate={[Validator.required]}
                    placeholder="説明"
                    label="説明 *"
                    multiline={true}
                    minRows={4}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="payment_form_type"
                    label="決済フォーム種別 *"
                    fullWidth
                    component={SelectRedux}
                    validate={[Validator.required]}
                    disabled={isNew ? false : true}
                    onChange={evt => setStatus({...status, name:evt.target.value})}
                  >
                    <MenuItem value="normal_payment">通常決済フォーム</MenuItem>
                    <MenuItem value="subscription_payment">継続課金決済フォーム</MenuItem>
                    <MenuItem value="transfer_payment">継続課金用データ移行フォーム</MenuItem>
                    {/* <MenuItem value="face_auth_payment">顔認証決済フォーム</MenuItem> */}
                  </Field>
                </FormControl>
              </Grid>
              <Hidden xsDown><Grid item sm={6}></Grid></Hidden>
              {formValues && formValues.get("payment_form_type") === "face_auth_payment" && 
                <React.Fragment>
                  <Grid item xs={12}>
                    <FormControlLabel control={<Field name="is_price?" component={CheckboxRedux} />} label="金額を設定する" />
                  </Grid>
                  {formValues && formValues.get("is_price?") &&
                    <React.Fragment>
                      <Grid container item xs={12} sm={6} alignItems="flex-start">
                        <FormControl className={classes.formControl}>
                          <Field
                            name="price"
                            label="金額"
                            type="number"
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                            component={TextFieldRedux}
                            validate={[Validator.required, Validator.price]}
                            className={classes.field}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {formValues && formValues.get("payment_form_type") === "normal_payment" &&
                <React.Fragment>
                  <Grid item xs={12}>
                    <FieldArray name="payment_services_attributes" component={renderServices} setPaymentFormData={setPaymentFormData} isNormalPayment={!!(formValues && formValues.get("payment_form_type") === "normal_payment")} isNew={isNew} accessToken={accessToken}/>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="image"
                        component={MaterialDropZone}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        type="file"
                        showPreviews
                        files={(formValues && formValues.get("image")) ? [formValues.get("image")] : []}
                        setFiles={setImageFiles}
                        maxSize={5000000}
                        filesLimit={1}
                        text="ここに画像をドラッグ＆ドロップまたはクリックして画像を選択してください"
                      />
                    </FormControl>
                  </Grid> 
                </React.Fragment>
              }
              {formValues && formValues.get("payment_form_type") === "subscription_payment" &&
                <React.Fragment>
                  <Grid item xs={12}>
                    <FormLabel component="label">継続決済方法 *</FormLabel>
                    <Field
                      name="sbsc_payment_method"
                      style={{display: "flex", flexDirection: "row"}}
                      component={renderRadioGroup}
                      validate={[Validator.required]}
                    >
                      <FormControlLabel disabled={isNew ? false : true} value="auto_month" control={<Radio />} label="自動（月払い）"/>
                      <FormControlLabel disabled={isNew ? false : true} value="auto_year" control={<Radio />} label="自動（年払い）" />
                      <FormControlLabel disabled={isNew ? false : true} value="custom" control={<Radio />} label="手動" />
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend">入会金</FormLabel>
                    <FormControlLabel control={<Field name="has_initial_price" component={SwitchRedux} disabled={false}/>}/>
                    必要
                  </Grid>
                  {formValues && formValues.get("has_initial_price") &&
                    <React.Fragment>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} >
                          <Field
                            name="initial_price"
                            label="入会金額（税込） *"
                            type="number"
                            fullWidth
                            placeholder="入会金額（税込）"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                            component={TextFieldRedux}
                            validate={[Validator.required, Validator.num]}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel component="legend">入会金説明</FormLabel>
                        <FormControl className={classes.formControl}>
                          <Field
                            name="initial_price_description"
                            component={TextEditor}
                            className={classes.field}
                            accessToken={accessToken}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  }
                  <Grid item xs={12}>
                    <FieldArray name="payment_services_attributes" component={renderServices} setPaymentFormData={setPaymentFormData} formValues={formValues} isNew={isNew} accessToken={accessToken}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel component="label">ログイン方法 *</FormLabel>
                    <Field
                      name="login_method"
                      style={{display: "flex", flexDirection: "row"}}
                      component={renderRadioGroup}
                      validate={[Validator.required]}
                    >
                      <FormControlLabel disabled={true} value="email" control={<Radio />} label="メールアドレス" />
                      <FormControlLabel disabled={isNew ? false : true} value="user_no" control={<Radio />} label="会員番号" />
                    </Field>
                  </Grid>
                </React.Fragment>
              }
              {formValues && formValues.get("payment_form_type") === "transfer_payment" &&
                <React.Fragment>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="parent_form_id"
                        label='決済フォーム *'
                        component={SelectSuggestions}
                        inputId="react-select-single"
                        TextFieldProps={{
                          label: '決済フォーム *',
                          InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                          }
                        }}
                        placeholder='決済フォームを検索し選択してください'
                        formValues={formValues}
                        validate={[Validator.required]}
                        options={indexPaymentFormData && indexPaymentFormData.length > 0 && indexPaymentFormData.map( (paymentForm, key) => ({value: paymentForm.id, label: paymentForm.attributes.name}) ) }
                        className={classes.field}
                        disabled={isNew ? false : true}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              }
              <Grid item xs={12}>
                <FormLabel component="legend">規約</FormLabel>
                <FormControlLabel control={<Field name="is_terms" component={SwitchRedux} disabled={false}/>}/>
                必要
              </Grid>
              {formValues && formValues.get("is_terms") &&
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="terms_name"
                        label="規約名 *"
                        fullWidth
                        validate={[Validator.required, Validator.length2]}
                        component={TextFieldRedux}
                        className={classes.field}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel control={<Field name="terms_required" component={SwitchRedux} disabled={false}/>}/>
                    チェック必須
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                          name="terms"
                          component={TextEditor}
                          className={classes.field}
                          accessToken={accessToken}
                          validate={[Validator.required]}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              }
              <Grid item xs={12}>
                <FormLabel component="legend">Googleタグマネージャー</FormLabel>
                <FormControlLabel control={<Field name="is_gtm" component={SwitchRedux} disabled={false}/>}/>
                必要
              {formValues && formValues.get("is_gtm") &&
                <React.Fragment>
                  <Grid item xs={12} sm={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="gtm_head"
                        className={classes.field}
                        component={TextFieldRedux}
                        validate={[Validator.required, Validator.html]}
                        placeholder="head要素内に貼り付けるコードを入力してください。"
                        label="head要素内コード *"
                        multiline={true}
                        minRows={4}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="gtm_body"
                        className={classes.field}
                        component={TextFieldRedux}
                        validate={[Validator.required, Validator.html]}
                        placeholder="body要素内に貼り付けるコードを入力してください。"
                        label="body要素内コード *"
                        multiline={true}
                        minRows={4}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              }
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend">決済方法</FormLabel>
                <FormControlLabel control={<Field name="is_credit_card" component={CheckboxRedux} checked={true} disabled />} label="クレジットカード決済" />
                {formValues && formValues.get("payment_form_type") === "normal_payment" &&
                  <>
                    <FormControlLabel control={<Field name="is_cvs" component={CheckboxRedux} />} label="コンビニ決済" />
                    <FormControlLabel control={<Field name="is_paypay" component={CheckboxRedux} />} label="PayPay" />
                    {/* <FormControlLabel control={<Field name="is_linepay" component={CheckboxRedux} />} label="LINE Pay" /> */}
                    <FormControlLabel control={<Field name="is_alipay" component={CheckboxRedux} />} label="Alipay" />
                    <FormControlLabel control={<Field name="is_applepay" component={CheckboxRedux} />} label="Apple Pay" />
                  </>
                }
              </Grid>
              {/* {formValues && formValues.get("is_credit_card") && */}
                <Grid item xs={12}>
                  <FormLabel component="legend">3Dセキュアを利用する</FormLabel>
                  { !!companyData && !!companyData.three_d_secure_key && !!companyData.three_d_iv ? (
                    <>
                      <FormControlLabel control={<Field name="is_three_d_secure" component={SwitchRedux} />}/>利用する
                    </>
                  ) : (
                    <>
                      <p>利用する場合は3Dセキュアキーと3Dセキュアベクトルの設定が必要です。
                      <br/>[システム管理] &gt; [加盟店情報] &gt; [サービス設定] から設定してください。</p> 
                    </>
                  )}
                </Grid>
              {/* } */}
              {/* <Grid item xs={12}>
                <FormLabel component="legend">会員登録</FormLabel>
                必要なし　
                <FormControlLabel control={<Field name="is_user_regist" component={SwitchRedux}/>}/>
                必要
              </Grid> */}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {formValues && formValues.get("payment_form_type") === "normal_payment" &&
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="split_available"
                          label="分割払い *"
                          fullWidth
                          component={SelectRedux}
                          validate={[Validator.required]}
                          onChange={evt => setStatus({...status, name:evt.target.value})}
                        >
                          <MenuItem value="able">可能</MenuItem>
                          <MenuItem value="not_able">不可</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  }
                  {/* {formValues && formValues.get("payment_form_type") === "subscription_payment" &&
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl}>
                              <Field
                                name="initial_price"
                                label="初期費用"
                                type="number"
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                }}
                                component={TextFieldRedux}
                                validate={[Validator.required, Validator.num]}
                                required
                                className={classes.field}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <Field
                            name="plan_change_available"
                            label="ユーザーによるプラン変更"
                            fullWidth
                            component={SelectRedux}
                            validate={[Validator.required]}
                            required
                            onChange={evt => setStatus({...status, name:evt.target.value})}
                          >
                            <MenuItem value="changeable">可能</MenuItem>
                            <MenuItem value="not_changeable">不可</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  } */}
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="status"
                        label="ステータス *"
                        fullWidth
                        component={SelectRedux}
                        validate={[Validator.required]}
                        onChange={evt => setStatus({...status, name:evt.target.value})}
                      >
                        <MenuItem value="active">有効</MenuItem>
                        <MenuItem value="not_active">無効</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="public_from"
                    component={DateRedux}
                    placeholder="公開開始日"
                    label="公開開始日"
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="public_to"
                    component={DateRedux}
                    placeholder="公開終了日"
                    label="公開終了日"
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              { formValues && !((formValues.get("payment_form_type") == "subscription_payment" && formValues.get("sbsc_payment_method") == "custom") || formValues.get("payment_form_type") == "transfer_payment") &&
              <>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={10} sm={11}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="spsv_free_2"
                        label="SPSV自由領域2"
                        fullWidth
                        validate={[Validator.spsvFreeMaxlength, Validator.spsvFree]}
                        component={TextFieldRedux}
                        className={classes.field}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={2} sm={1} alignItems="center">
                    <Tooltip title="本フォーム決済時、設定した内容をSPSVの自由領域2に連携します" placement="right">
                      <IconButton className={classes.button}>
                        <i className="ion-ios-help-outline"/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={10} sm={11}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="spsv_free_3"
                        label="SPSV自由領域3"
                        fullWidth
                        validate={[Validator.spsvFreeMaxlength, Validator.spsvFree]}
                        component={TextFieldRedux}
                        className={classes.field}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={2} sm={1} alignItems="center">
                    <Tooltip title="本フォーム決済時、設定した内容をSPSVの自由領域3に連携します" placement="right">
                      <IconButton className={classes.button}>
                        <i className="ion-ios-help-outline"/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              </>
              }
            </Grid>
          </div>
            { isNew ? 
              // 余白用
              <div className={classes.btnArea}>
                <Grid container justifyContent="center">
                </Grid>
              </div>
            :
              <div>
                <div className={classes.btnArea}>
                  <Grid container justifyContent="center">
                    <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/payment_form/index">
                      戻る
                    </Button>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      更新
                    </Button>
                  </Grid>
                </div>
                <div className={classes.btnArea}>
                  <Grid container justifyContent="center">
                    <Button variant="contained" color="primary" size="large" component={Link} to={"/admin/payment_form/" + `${formId}` + "/item"}>
                      フォーム項目詳細
                    </Button>
                  </Grid>
                </div>
                { formValues && formValues.get("form_url") &&
                  <div className={classes.btnArea}>
                    <Grid container justifyContent="center">
                      <Button variant="contained" color="primary" size="large" onClick={() => setUrlScriptData({url: formValues.get("form_url"), open: true})}>
                        埋め込みコード
                      </Button>
                    </Grid>
                  </div>
                }
                <div className={classes.btnArea}>
                  <Grid container justifyContent="center">
                    <DeleteDialog color="secondary" className={classes.button} message="このフォームを削除する" deleteForm={deleteForm}/>
                  </Grid>
                </div>
              </div>
            }
        </section>
      </PapperBlock>
        { isNew &&
          <PapperBlock title={"決済フォーム項目設定"} whiteBg icon="ios-contact-outline" desc="">
            <section className={classes.formWrap}>
            <FieldArray name="form_items_attributes" component={renderFormItems} isNew={isNew} accessToken={accessToken} display={true} syncErrors={synchronousError} formValues={formValues} isNormalPayment={!!(formValues && formValues.get("payment_form_type") === "normal_payment")} isCvs={!!(formValues && formValues.get("is_cvs"))} />
            <div className={classes.btnArea}>
              <Grid container justifyContent="center">
                <Button variant="contained" color="primary" size="large" type="submit">
                  登録
                </Button>
              </Grid>
            </div>
            </section>
          </PapperBlock>
        }
      </form>
      <UrlScriptDialog urlScriptData={urlScriptData} />
    </div>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'paymentFormForm',
  enableReinitialize: true
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    deco: state.getIn([reducer, 'decoration']),
    formValues: getFormValues("paymentFormForm")(state),
    synchronousError : getFormSyncErrors("paymentFormForm")(state)
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
