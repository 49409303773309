import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import * as Validator from '../../../utils/validate'
import Tooltip from '@material-ui/core/Tooltip';
import { fromJS } from 'immutable';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { TextFieldRedux, CheckboxRedux, SwitchRedux } from '../../../components/Forms/ReduxFormMUI';
import TextEditor from '../Companies/TextEditor';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function renderPlans(props) {

  const {
    classes,
    fields,
    accessToken,
    isNormalPayment,
    hasQuantity,
    meta: { touched, error, submitFailed },
  } = props;

  const addPlan = (name = "", content = "", price = "", max_number = "", stock = "", image = "", free_period = "") => {
    fields.push(fromJS({name: name, content: content, price: price, max_number: max_number, stock: stock, image: image, free_period: free_period}))
  };

  useEffect(() => {
    fields.getAll() && updatePlanIndex();
  },[fields]);

  const [indexData, setIndexData] = useState();
  const updatePlanIndex = (index) => {
    const data = fields.getAll().toJS().map((item, plan_index) => {
      if (plan_index === index) {
        return { ...item, _destroy: true };
      }
      return item;
    });
    let planIndex = 1;
    const newData = data.map(item => {
      if (!item._destroy) {
        return { ...item, planIndex: planIndex++ };
      }
      return item;
    });
    setIndexData(newData)
  }

  return (
    <div>
      {fields.map((plan, index) => (
        fields.get(index).get("_destroy") ? "" :
          <div key={index}>
          <Typography className={classes.title} style={{ float:'left' }}>プラン { indexData && indexData[index] && indexData[index].planIndex}</Typography>
          { index !== 0 &&
            <Tooltip title="プランを削除" placement="left">
              <FormControlLabel control={(<Field name={`${plan}._destroy`} component={CheckboxRedux} icon={<CloseIcon className={classes.icon} />}　style={{ margin: 0, padding: 0 }}/>)} style={{ float:'right' }}/>
            </Tooltip>
          }
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <Field
                  name={`${plan}.name`}
                  label="プラン名 *"
                  placeholder="プラン名"
                  fullWidth
                  component={TextFieldRedux}
                  validate={[Validator.required, Validator.notUnderBar]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <Field
                  name={`${plan}.price`}
                  label="プラン金額（税込） *"
                  type="number"
                  placeholder="プラン金額（税込）"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                  }}
                  fullWidth
                  component={TextFieldRedux}
                  validate={[Validator.required, Validator.num]}
                />
              </FormControl>
            </Grid>
            { isNormalPayment && hasQuantity &&
              <>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name={`${plan}.max_number`}
                      label="購入可能個数"
                      type="number"
                      placeholder="購入可能個数"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">個</InputAdornment>,
                      }}
                      fullWidth
                      validate={[Validator.num]}
                      component={TextFieldRedux}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={6} alignItems="center">
                  <Tooltip title="購入個数に制限がある場合は設定してください　制限を設定しない場合は一度の決済で1つまでしか購入できません" placement="right">
                    <IconButton className={classes.button}>
                      <i className="ion-ios-help-outline" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name={`${plan}.stock`}
                      label="在庫数"
                      type="number"
                      placeholder="在庫数"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">個</InputAdornment>,
                      }}
                      fullWidth
                      validate={[Validator.stock]}
                      component={TextFieldRedux}
                    />
                  </FormControl>
                </Grid>
                <Grid container item sm={6} alignItems="center">
                  <Tooltip title="在庫数に限りがある場合は設定してください" placement="right">
                    <IconButton className={classes.button}>
                      <i className="ion-ios-help-outline" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            }
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 30 }}>
            <FormLabel component="legend">プラン内容</FormLabel>
            <FormControl className={classes.formControl}>
              <Field
                name={`${plan}.content`}
                component={TextEditor}
                className={classes.field}
                accessToken={accessToken}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl} >
              <Field
                name={`${plan}.free_period`}
                label="無料期間"
                type="number"
                placeholder="無料期間"
                InputProps={{
                  endAdornment: <InputAdornment position="end">日</InputAdornment>,
                }}
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required, Validator.num]}
                required
              />
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Field
                name={`${plan}.image`}
                component={MaterialDropZone}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                type="file"
                showPreviews
                files={fields.get(index).get("image") ? [fields.get(index).get("image")] : []}
                setFiles={setImageFiles}
                maxSize={5000000}
                filesLimit={1}
                text="ここに画像をドラッグ＆ドロップまたはクリックして画像を選択してください"
              />
            </FormControl>
          </Grid> */}
          </div>
        ))}
      <Grid container color="primary" justifyContent="center" style={{ marginTop: 20, marginBottom: 30 }}>
        <Tooltip title="プランを追加" placement="top">
          <Fab color="primary" aria-label="add" className={classes.button} onClick={() => addPlan()}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(renderPlans);
