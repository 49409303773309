import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, FieldArray } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, CheckboxRedux} from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import * as Validator from '../../../utils/validate'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import renderFormItemSelects from './renderFormItemSelects';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FloatingPanel from './FloatingPanel';
import { fromJS } from 'immutable';
import TextEditor from "../Companies/TextEditor";


function renderFormItems(props) {

  const [openForm, setOpenForm] = useState(false);

  const {
    classes,
    fields,
    isNew,
    display,
    syncErrors,
    formValues,
    isNormalPayment,
    isCvs,
    meta: { touched, error, submitFailed }
  } = props;

  const accessToken = props.accessToken;
  const [isDisplay, setIsDisplay] = useState(true);

  // 詳細取得
  useEffect(() => {
    if (!isNew) return;
    fields.removeAll();
    addItem("メールアドレス", "email", "メールアドレス", "", true, false, [], true, true);
    if (isNormalPayment) return;
    addItem("パスワード", "password", "パスワード", "", true, false, [], true, true);
    addItem("確認用パスワード", "password", "確認用パスワード", "", true, false, [], true, true);
  }, [isNew, isNormalPayment]);

  useEffect(() => {
    if (!isNew || !isNormalPayment || !isCvs) return;
    for (let index = fields.length - 1; index >= 0; index--) {
      if (["名前(姓)", "名前(セイ)", "固定電話番号"].includes(fields.get(index).get("name"))) {
        fields.remove(index)
      }
    }
    addItem("名前(姓)", "text", "名前(姓)", "", true, false, [], true, true);
    addItem("名前(セイ)", "katakana", "名前(セイ)", "", true, false, [], true, true);
    addItem("固定電話番号", "tel", "固定電話番号", "", true, false, [], true, true);
  }, [isNew, isCvs]);

  useEffect(() => {setIsDisplay(display)}, []);

  const addItem = (name = "", form_type = "", placeholder = "", description = "", is_required = false, is_hidden = false, form_item_selects_attributes = [], name_disabled = false, form_type_disabled = false) => {
    if (isRequiredItem(name)) {
      is_required = true
      name_disabled = true
      form_type_disabled = true
    }
    fields.push(fromJS({name: name, form_type: form_type, placeholder: placeholder, description: description, is_required: is_required, is_hidden: is_hidden, form_item_selects_attributes: fromJS(form_item_selects_attributes), name_disabled: name_disabled, form_type_disabled: form_type_disabled}))
  };

  const isRequiredItem = name => {
    const defaultPattern = ["メールアドレス"].includes(name);
    const subscriptionPattern = !isNormalPayment && ["パスワード", "確認用パスワード"].includes(name);
    const cvsPattern = isNormalPayment && isCvs && ["名前(姓)", "名前(セイ)", "固定電話番号"].includes(name);
    return defaultPattern || subscriptionPattern || cvsPattern;
  }

  const makeOnDragEndFunction = fields => result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    fields.move(result.source.index, result.destination.index);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    marginBottom: 30,

    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <div>
      <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fields.map((item, index) => (
                fields.get(index).get("_destroy") ? "" :
                <Draggable
                  key={item}
                  draggableId={item}
                  index={index}
                >
                  {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Paper key={index} style={{ border: "1px solid silver", padding: 15, marginBottom: 0 }} elevation={4}>
                            {
                              ["label"].includes(fields.get(index).get("form_type")) ?
                                "" :
                                <FormControlLabel
                                  control={
                                    <Field
                                      name={`${item}.is_required`}
                                      component={CheckboxRedux}
                                      disabled={isRequiredItem(fields.get(index).get("name"))}
                                    />}
                                  label="必須項目に設定する"/>
                            }
                            { ["label"].includes(fields.get(index).get("form_type")) ? "" :
                              isRequiredItem(fields.get(index).get("name")) ?
                                <FormControlLabel control={(<Field name={`${item}.is_hidden`} component={CheckboxRedux} disabled inputProps={{ 'aria-label': 'disabled checkbox' }}/>)} label="非表示" /> :
                                <FormControlLabel control={(<Field name={`${item}.is_hidden`} component={CheckboxRedux} />)} label="非表示" />
                            }
                            { !(isRequiredItem(fields.get(index).get("name"))) &&
                              <Tooltip title="項目を削除" placement="left">
                                <FormControlLabel control={(<Field name={`${item}._destroy`} component={CheckboxRedux} icon={<CloseIcon className={classes.icon} />}　/>)} style={{ float:'right' }}/>
                              </Tooltip>
                            }
                            <Grid container spacing={3} color="primary" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl} >
                                  <Field
                                    name={`${item}.name`}
                                    label="項目名 *"
                                    placeholder="項目名"
                                    fullWidth
                                    component={TextFieldRedux}
                                    validate={[Validator.required, Validator.length]}
                                    disabled={fields.get(index).get("name_disabled") || isRequiredItem(fields.get(index).get("name")) }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl} >
                                  <Field
                                    name={`${item}.form_type`}
                                    fullWidth
                                    label="入力方式 *"
                                    component={SelectRedux}
                                    validate={[Validator.required]}
                                    disabled={fields.get(index).get("form_type_disabled") || isRequiredItem(fields.get(index).get("name"))}
                                    className={classes.field}
                                  >
                                    <MenuItem value="text">単行</MenuItem>
                                    <MenuItem value="text_area">複数行</MenuItem>
                                    <MenuItem value="tel">数字</MenuItem>
                                    <MenuItem value="katakana">カタカナ</MenuItem>
                                    {/* <MenuItem value="radio">ラジオボタン</MenuItem>
                                    <MenuItem value="checkbox">チェックボタン</MenuItem> */}
                                    <MenuItem value="select">プルダウン</MenuItem>
                                    { isDisplay &&
                                      <MenuItem value="nest_select">条件分岐プルダウン</MenuItem>
                                    }
                                    <MenuItem value="date">日付</MenuItem>
                                    <MenuItem value="terms_of_service">規約同意</MenuItem>
                                    <MenuItem value="label">ラベル</MenuItem>
                                    <MenuItem value="email">メールアドレス</MenuItem>
                                    <MenuItem value="image">画像ファイル</MenuItem>
                                    { fields.get(index).get("form_type") === "password" &&
                                      <MenuItem value="password">パスワード</MenuItem>
                                    }
                                  </Field>
                                </FormControl>
                              </Grid>

                              { ["terms_of_service"].includes(fields.get(index).get("form_type")) ?
                                <Grid item xs={12}>
                                  <FormControl className={classes.formControl} >
                                    <Field name={`${item}.terms_of_service`} component={TextEditor} className={classes.field} accessToken={accessToken} />
                                  </FormControl>
                                </Grid>
                              :
                                <Grid item xs={12}>
                                  { !["label", "image"].includes(fields.get(index).get("form_type")) &&
                                    <FormControl className={classes.formControl} >
                                      <Field
                                        name={`${item}.placeholder`}
                                        label="プレースホルダー"
                                        fullWidth
                                        component={TextFieldRedux}
                                      />
                                    </FormControl>
                                  }
                                  <FormControl className={classes.formControl} >
                                    <Field
                                      name={`${item}.description`}
                                      label="項目の説明"
                                      fullWidth
                                      component={TextFieldRedux}
                                      multiline={true}
                                      minRows={4}
                                    />
                                  </FormControl>
                                </Grid>
                              }

                              <Grid item xs={12}>
                              { !["radio", "select", "nest_select", "pull_down"].includes(fields.get(index).get("form_type")) ? "" :
                                <FieldArray
                                  name={`${item}.form_item_selects_attributes`}
                                  component={renderFormItemSelects}
                                  itemIndex={index}
                                  itemField={fields}
                                  form_type={fields.get(index).get("form_type")}
                                  syncErrors={syncErrors}
                                  formValues={formValues}
                                />
                              }
                              </Grid>
                            </Grid>
                          </Paper>
                        </div>
                      )
                    }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Grid container color="primary" justifyContent="center" style={{ marginTop: 20, marginBottom: 30 }}>
          <Tooltip title="項目を追加" placement="top">
            <Fab color="primary" aria-label="add" className={classes.button} onClick={() => setOpenForm(true)}>
              <AddIcon />
            </Fab>
          </Tooltip>
          <FloatingPanel openForm={openForm} setOpenForm={setOpenForm} title={"項目を選択してください"} addItem={addItem} isDisplay={isDisplay}/>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(renderFormItems);
