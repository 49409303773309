import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import brand from "dan-api/dummy/brand";
import PapperBlock from "dan-components/PapperBlock/PapperBlock";
import SearchForm from "../../../components/Forms/SearchForm";
import List from "../../../components/Lists/List";
import { withRouter } from "react-router";
import { withCookies } from "react-cookie";
import MySnackbar from "../../../components/Notification/StyledNotif";
import { connect } from "react-redux";
import {
  onFetchDeleteExportLogAction,
  onFetchDownloadExportLogAction,
  onFetchIndexExportLogAction
} from "../../../redux/actions/exportLog";
import accessToken from "../../../utils/accessToken";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../components/Forms/user-jss";
import Loading from "../../../components/Loading/index";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { dateTimeFormat, enumToJa } from "../../../utils/format";

const Index = props => {
  // TODO: 検索条件クリアボタンを追加
  const { classes } = props;
  const location = props.location;
  const { companies } = props.internalValues;
  const [searchItems, setSearchItems] = useState([
    {
      label: "エクスポート日時(開始)",
      name: "from",
      value: null,
      // TODO: yyyy-mm-dd hh:mm: 形式に変換してパラメータにセットすること
      formType: "datetime"
    },
    {
      label: "エクスポート日時(終了)",
      name: "to",
      value: null,
      // TODO: yyyy-mm-dd hh:mm: 形式に変換してパラメータにセットすること
      formType: "datetime"
    },
    {
      label: "種類",
      name: "class_name",
      value: "",
      formType: "select",
      menuItems: [{key: "会員情報", value: "SkyRocketModel::User"}, {key: "決済履歴情報", value: "SkyRocketModel::PaymentHistory"}]
    },
    {
      label: "加盟店名",
      name: "company_id",
      value: "",
      formType: "select",
      super: true,
      menuItems: companies
        ? companies.map(company => ({ key: company.name, value: company.id }))
        : null
    }
  ]);
  const [listItems, setlistItems] = useState([
    {
      label: "種類",
      name: "class-name",
      // TODO: SkyRocketModel::Userから「会員情報」を取得する共通メソッドを作成すること
      format: value => {
        switch (value["class-name"]) {
          case "SkyRocketModel::User":
            return "会員情報";
          case "SkyRocketModel::PaymentHistory":
            return "決済履歴情報";
          default:
            return "";
        }
      }
    },
    {
      label: "エクスポート日時",
      name: "created-at",
      format: value => {
        return dateTimeFormat(value["created-at"]);
      }
    },
    { label: "ファイル名", name: "file-name" },
    {
      label: "ステータス",
      name: "status",
      format: value => {
        return enumToJa(value["status"]);
      }
    }
  ]);
  const downloadAction = form => {
    if (form.attributes.status !== "success") {
      displayNotification(
        "CSVエクスポートが成功していないためダウンロードできません"
      );
      return;
    }
    props.onFetchDownloadExportLog({
      accessToken: accessToken(props),
      data: { id: form.id },
      onSuccess: data => {
        const url = window.URL.createObjectURL(data.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          data.headers["content-disposition"].match(/filename="([^"]+)"/)[1]
        ); // ファイル名を設定
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // リンクをクリーンアップ
      },
      onErrorWithNotice: error => {
        displayNotification("ダウンロードできませんでした");
      }
    });
  };
  const [selectedId, setSelectedId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const deleteAction = form => {
    if (form.attributes.status !== "success") {
      displayNotification("CSVエクスポートが成功していないため削除できません");
      return;
    }
    setSelectedId(form.id);
    setIsDeleteDialogOpen(true);
  };

  function handleClose() {
    setIsDeleteDialogOpen(false);
  }

  function handleSubmit() {
    props.onFetchDeleteExportLog({
      accessToken: accessToken(props),
      data: { id: selectedId },
      onSuccess: data => {
        setIsDeleteDialogOpen(false);
        displayNotification("削除しました");
        submitForm();
      },
      onErrorWithNotice: error => {
        setIsDeleteDialogOpen(false);
        displayNotification("削除できませんでした");
      }
    });
  }

  // TODO: ステータスが成功以外の場合、それぞれのアクション内でエラーメッセージを出すのではなくButtonコンポーネントのdisabledをtrueにしたい
  const actions = [
    { label: "ダウンロード", function: downloadAction },
    { label: "削除", function: deleteAction }
  ];
  const [loading, setLoading] = useState(true);
  const [exportLogData, setExportLogData] = useState("");
  // TODO: valuesの使い道をはっきりさせる（呼び出し元で呼ばれているが、なんのために呼んでいるのか確認してから削除すること）
  const submitForm = (values, page = 1) => {
    let data = {};
    searchItems.forEach(item => {
      data[item.name] = item.value;
    });
    data.page = page;
    props.onFetchIndexExportLog({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data;
        setExportLogData(item);
        item.data.length === 0
          ? displayNotification("検索結果がありませんでした", "warning")
          : setLoading(false);
      },
      onErrorWithNotice: error => {
        displayNotification(error);
      }
    });
  };
  // メッセージ関連
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : "",
    variant: location.message ? location.variant : "info"
  });
  const displayNotification = (message, valiant = "error") => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  };
  const changeValue = (index, value) => {
    searchItems[index].value = value;
    setSearchItems(searchItems.slice());
  };
  useEffect(() => {
    submitForm();
  }, []);
  return (
    <>
      <Helmet>
        <title>{brand.name + " - CSVエクスポート一覧"}</title>
      </Helmet>
      <PapperBlock
        title="CSVエクスポート検索"
        whiteBg
        icon="ios-search-outline"
        desc=""
      >
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            {searchItems.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <SearchForm
                    searchItem={item}
                    key={index}
                    id={index}
                    changeValue={changeValue}
                    role={props.internalValues.role}
                  />
                </Grid>
              );
            })}
          </Grid>
        </section>
        <div className={classes.btnArea}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={submitForm}
              >
                検索
              </Button>
            </Grid>
          </Grid>
        </div>
      </PapperBlock>
      {loading || exportLogData.data.length === 0 ? (
        ""
      ) : (
        <PapperBlock
          title="CSVエクスポート一覧"
          whiteBg
          icon="ios-list"
          desc=""
          submitForm={submitForm}
        >
          {props.loadingExportLogIndex ? (
            <div>
              <List
                listItems={listItems}
                searchResult={exportLogData}
                submitForm={submitForm}
                role={props.internalValues.role}
                actions={actions}
                browsingRight={props.internalValues.browsingRight}
              />
              <Loading />
            </div>
          ) : (
            <List
              listItems={listItems}
              searchResult={exportLogData}
              submitForm={submitForm}
              role={props.internalValues.role}
              actions={actions}
              browsingRight={props.internalValues.browsingRight}
            />
          )}
          {/* TODO: Dialogコンポーネントを直接実装するのではなく、DeleteDialog.jsコンポーネントを改修してそっちを呼び出すようにしたい */}
          <Dialog
            open={isDeleteDialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"削除しますか?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                削除してしまうと元に戻すことは出来ません！
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                戻る
              </Button>
              <Button onClick={handleSubmit} color="primary">
                削除
              </Button>
            </DialogActions>
          </Dialog>
        </PapperBlock>
      )}
      <MySnackbar
        onClose={() => setNotification({ ...notification, openStyle: false })}
        notification={notification}
      />
    </>
  );
};

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(["internalReducers", "internalValues"]),
    loadingExportLogIndex: state.getIn([
      "exportLogReducers",
      "loadingExportLogIndex"
    ]),
    loadingExportLogDelete: state.getIn([
      "exportLogReducers",
      "loadingExportLogDelete"
    ]),
    loadingExportLogDownload: state.getIn([
      "exportLogReducers",
      "loadingExportLogDownload"
    ])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexExportLog: data => {
      dispatch(onFetchIndexExportLogAction(data));
    },
    onFetchDeleteExportLog: data => {
      dispatch(onFetchDeleteExportLogAction(data));
    },
    onFetchDownloadExportLog: data => {
      dispatch(onFetchDownloadExportLogAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));
