import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, DateRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import SubscriptionPaymentPlan from './SubscriptionPaymentPlan';
import NormalPaymentPlan from './NormalPaymentPlan';
import FormItemAnswer from './FormItemAnswer';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { withRouter } from 'react-router';

function Form(props) {

  const [status, setStatus] = useState("");
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);

  const jumpPaymentHistory = (userNo) => {
    props.history.push({ pathname: '/admin/payment_history/index', userNo });
  };

  const notifyAction = () => {
    setIsNotifyDialogOpen(true);
  };

  function handleClose() {
    setIsNotifyDialogOpen(false);
  }

  const handleNotify = (userID) => {
    notifyOrder(userID);
    setIsNotifyDialogOpen(false);
  }

  const {
    classes,
    handleSubmit,
    deleteForm,
    notifyOrder,
    handleDeleteImage,
    handleDownloadImage,
    initialValues,
    browsingRight,
  } = props;

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        <div>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="last_name"
                    label="姓"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="first_name"
                    label="名"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="last_name_kana"
                    label="セイ"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="first_name_kana"
                    label="メイ"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="email"
                    label="メールアドレス"
                    fullWidth
                    autoComplete="email"
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="tel"
                    label="固定電話番号"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="mobile_tel"
                    label="携帯電話番号"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="birthday"
                    label="生年月日"
                    fullWidth
                    component={DateRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="zip"
                    label="郵便番号"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="pref"
                    label="都道府県"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="city"
                    label="市区町村"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="street"
                    label="番地"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="street2"
                    label="建物名"
                    fullWidth
                    component={TextFieldRedux}
                    disabled={browsingRight !== 1}
                    className={classes.field}
                    type={browsingRight == 1 ? 'text' : 'password'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="user_no"
                    label="会員番号"
                    fullWidth
                    autoComplete="user_no"
                    component={TextFieldRedux}
                    disabled
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="user_type"
                    label="会員種別"
                    fullWidth
                    component={SelectRedux}
                    className={classes.field}
                    disabled
                    onChange={evt => setStatus({...status, name:evt.target.value})}
                  >
                    <MenuItem value="normal">通常決済会員</MenuItem>
                    <MenuItem value="guest">非会員</MenuItem>
                    <MenuItem value="plan">継続課金会員</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.demo} >
                <div className={classes.picker}>
                  <Field
                  name="created_at"
                  component={DateRedux}
                  label="入会日"
                  disabled
                  className={classes.field}
                />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="status"
                    label="ステータス"
                    fullWidth
                    component={SelectRedux}
                    className={classes.field}
                    onChange={evt => setStatus({...status, name:evt.target.value})}
                  >
                    <MenuItem value="approved">有効</MenuItem>
                    <MenuItem value="not_approve">無効</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              {initialValues && 
                (initialValues.toJS().user_type == 'plan' ?
                  <>
                    <SubscriptionPaymentPlan subscriptions={initialValues.toJS().subscriptions_attributes}/>
                    {initialValues.toJS().subscriptions_attributes[0].form_item_answers.length > 0 &&
                      <FormItemAnswer objects={initialValues.toJS().subscriptions_attributes} handleDeleteImage={handleDeleteImage} handleDownloadImage={handleDownloadImage}/>
                    }
                  </>:
                  <>
                    <NormalPaymentPlan paymentHistories={initialValues.toJS().payment_histories_attributes}/>
                    {initialValues.toJS().payment_histories_attributes[0].form_item_answers.length > 0 &&
                      <FormItemAnswer objects={initialValues.toJS().payment_histories_attributes} handleDeleteImage={handleDeleteImage} handleDownloadImage={handleDownloadImage}/>
                    }
                  </>
                )
              }
          </Grid>
        </div>
        <div className={classes.btnArea}>
          <Grid container justifyContent="center">
            <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/user/index">
              戻る
            </Button>
            <Button variant="contained" color="primary" size="large" type="submit">
              更新
            </Button>
          </Grid>
        </div>
        { initialValues && initialValues.toJS().user_type == 'plan' &&
          <div className={classes.btnArea}>
            <Grid container justifyContent="center">
              <Button 
                variant="contained" 
                color="primary" 
                size="large" 
                onClick={notifyAction}
              >
                クレカ変更依頼
              </Button>
            </Grid>
            <Dialog
              open={isNotifyDialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"通知しますか？"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  対象の会員にクレカ変更依頼通知が送信されます
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  戻る
                </Button>
                <Button onClick={() => handleNotify(initialValues.toJS().id)} color="primary">
                  通知
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        }
        <Grid container justifyContent="center">
          <Button 
            variant="contained" 
            color="primary" 
            size="large" 
            onClick={() => jumpPaymentHistory(initialValues.toJS().user_no)}
          >
            決済履歴
          </Button>
        </Grid>
        <div className={classes.btnArea}>
          <Grid container justifyContent="center">
            <DeleteDialog color="secondary" className={classes.button} message="この会員を削除する" deleteForm={deleteForm}/>
          </Grid>
        </div>
      </form>
    </section>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'userForm',
  enableReinitialize: true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration'])
  }),
)(FormReduxed);

export default withStyles(styles)(withRouter(FormReduxedMapped));
