import React from 'react';
import LanguageProvider from './containers/LanguageProvider';
import history from './utils/history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import configureStore from './redux/configureStore';
import { translationMessages } from './i18n';
import App from './containers/App';
import FontFaceObserver from 'fontfaceobserver';
import 'sanitize.css/sanitize.css';
import './styles/layout/base.scss';
import { CookiesProvider } from 'react-cookie'

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);


// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

class SkyRocketAdmin extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
      return(
        <Provider store={store}>
          <CookiesProvider>
            <LanguageProvider messages={translationMessages}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </LanguageProvider>
          </CookiesProvider>
        </Provider>
    );
  }
}
export default SkyRocketAdmin;
